import styled from "@emotion/styled";
import "./App.css";
import Policies from "./components/Policies";
import Services from "./components/Services";
import Scheduling from "./components/Scheduling";
import About from "./components/About";
import Text from "./atoms/Text";
import NavBar from "./components/NavBar";
import { ReactComponent as AdornedByEmilyLogo } from "./assets/adorned-by-emily-logo.svg";
import PermanentJewelry from "./components/PermanentJewelry";

const Main = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: #cedeeb;
  text-align: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
`;

const LogoWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
`;

const Footer = styled.div`
  margin-top: auto;
`;

const App = () => {
  //just for Cody
  return (
    <Main>
      <NavBar />
      <LogoWrapper>
        <AdornedByEmilyLogo style={{ height: "90%", width: "90%" }} />
      </LogoWrapper>
      <Scheduling />
      <Services />
      <PermanentJewelry />
      <Policies />
      <About />
      <Footer>
        <Text>Copyright © 2025, All Rights Reserved</Text>
      </Footer>
    </Main>
  );
};

export default App;
