import styled from "@emotion/styled";
import { useState } from "react";
import NavLink, { NavLinkProps } from "../atoms/NavLink";
import { ReactComponent as HamburgerIcon } from "../assets/hamburger-menu.svg";
import { ReactComponent as AdornedByEmilyLongLogo } from "../assets/adorned-by-emily-long-logo.svg";

const NavBarLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const NavBarContainer = styled.div`
  display: flex;
  height: 60px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  background: linear-gradient(rgb(58, 102, 140) 0%, rgba(0, 0, 0, 0) 100%) 0% 0%
    no-repeat padding-box padding-box transparent;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  @media (max-width: 1025px) {
    background: linear-gradient(160deg, rgb(58, 102, 140) 0%, #588bb7 100%) 0%
      0% no-repeat padding-box padding-box transparent;
    font-size: 1.6rem;
    padding: 0.5rem;
  }
`;

const Links = styled.nav`
  width: 100%;
  margin-left: 2rem;
  margin-right: 2rem;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1025px) {
    display: flex;
  }
`;

const HamburgerButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;

  & svg {
    fill: white;
    height: 30px;
    width: 30px;
    margin-left: 1rem;
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

const MobileNavBar = styled.div`
  background: linear-gradient(35deg, rgb(58, 102, 140) 0%, #588bb7 100%) 0% 0%
    no-repeat padding-box padding-box transparent;
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 4.7rem;

  @media (min-width: 1025px) {
    display: none;
  }
`;

const MobileLinks = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 5px;
`;

const linkData: NavLinkProps[] = [
  {
    label: "Scheduling",
    href: "#scheduling",
  },
  {
    label: "Piercings",
    href: "#services",
  },
  {
    label: "Jewelry",
    href: "#permanent-jewelry",
  },
  {
    label: "Policies",
    href: "#policies",
  },
  {
    label: "Shop",
    href: "https://ember-studio.square.site/s/shop",
  },
];

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const links = linkData.map((link) => (
    <NavLink key={link.label} isOpen={isOpen} setIsOpen={setIsOpen} {...link} />
  ));
  return (
    <NavBarContainer>
      <NavBarLogoWrapper>
        <AdornedByEmilyLongLogo
          style={{ height: "50%", width: "75%", marginLeft: "0.5rem" }}
        />
      </NavBarLogoWrapper>
      <Links>{links}</Links>
      <HamburgerButton onClick={() => setIsOpen(!isOpen)}>
        <HamburgerIcon />
      </HamburgerButton>
      {isOpen && (
        <MobileNavBar>
          <MobileLinks>{links}</MobileLinks>
        </MobileNavBar>
      )}
    </NavBarContainer>
  );
};

export default NavBar;
